import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GeneralInfoTable = _resolveComponent("GeneralInfoTable")!
  const _component_SimpleCard = _resolveComponent("SimpleCard")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "block max-w-screen-md mx-auto my-5",
    to: {
      name: 'VerseIntertextDetail',
      params: { id: _ctx.intertext.id },
    }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SimpleCard, null, {
        default: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString(_ctx.intertext.title), 1),
          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('intertext.editor')) + ": " + _toDisplayString(_ctx.intertext.entry_author), 1),
          _createVNode(_component_GeneralInfoTable, { fields: _ctx.tableFields }, null, 8, ["fields"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["to"]))
}