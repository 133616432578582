import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-center py-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerseBar = _resolveComponent("VerseBar")!
  const _component_Summary = _resolveComponent("Summary")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VerseBar),
    (_ctx.isLoaded(_ctx.intertextVerseResults))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h3", _hoisted_2, [
            (_ctx.intertextVerseResults.payload.length == 1)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('intertext.result_description_singular', {
            count: _ctx.intertextVerseResults.payload.length,
            sura: _ctx.$route.params.sura,
            verse: _ctx.$route.params.verse,
          })), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('intertext.result_description_plural', {
            count: _ctx.intertextVerseResults.payload.length,
            sura: _ctx.$route.params.sura,
            verse: _ctx.$route.params.verse,
          })), 1)
                ], 64))
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sorted, (r) => {
            return (_openBlock(), _createBlock(_component_Summary, {
              key: r.title,
              intertext: r
            }, null, 8, ["intertext"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}