
import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { IntertextSummary } from '@/interfaces/IntertextSummary';
import GeneralInfoTable from '@/components/global/GeneralInfoTable.vue';
import SimpleCard from '@/components/global/SimpleCard.vue';
import { makeCategory } from '@/interfaces/IntertextDetail';

export default defineComponent({
  components: {
    SimpleCard,
    GeneralInfoTable,
  },
  props: { intertext: Object as PropType<IntertextSummary> },
  setup(props) {
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });
    const tableFields = computed(() => {
      if (props.intertext) {
        return [
          [t('intertext.tuk_number'), props.intertext.id],
          [t('intertext.original_language'), props.intertext.language],
          [t('global.location'), props.intertext.location],
          [t('global.date'), props.intertext.dated],
          [t('intertext.category'), makeCategory(props.intertext)],
        ];
      }
      return [[]];
    });

    return { tableFields };
  },
});
