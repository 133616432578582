import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowNavigation = _resolveComponent("ArrowNavigation")!

  return (_openBlock(), _createBlock(_component_ArrowNavigation, {
    textLeft: _ctx.$t('navigation.next_verse'),
    textRight: _ctx.$t('navigation.previous_verse'),
    leftLink: _ctx.leftLink,
    rightLink: _ctx.rightLink
  }, null, 8, ["textLeft", "textRight", "leftLink", "rightLink"]))
}