import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "table-auto" }
const _hoisted_2 = { class: "align-top" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (f) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: f[0],
          class: ""
        }, [
          _createElementVNode("td", _hoisted_2, _toDisplayString(f[0]) + ":", 1),
          _createElementVNode("td", {
            class: "align-bottom pl-2",
            innerHTML: f[1]
          }, null, 8, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}